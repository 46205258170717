import React, { useEffect, useState, useRef } from "react"
import Helmet from "react-helmet"
import ReactMarkdown from "react-markdown"
import { useMediaPredicate } from "react-media-hook"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import Modal from "react-modal"
import { Form, withFormik } from "formik"
import * as Yup from "yup"
/* import NumberFormat from "react-number-format" */
import gql from "graphql-tag"
import { useLazyQuery, useMutation } from "@apollo/react-hooks"
import Cookies from 'universal-cookie'
import moment from "moment"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Card, CardPrecious, CardTnB } from "../../components/card/card"
import { CoverMain } from "../../components/cover-main/cover-main"
import { CardSlider } from "../../components/card-slider/card-slider"
import { Container, Section } from "../../components/grid/grid"
import { TabLinkPrecious } from "../../components/tab-header/tab-header"
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const axios = require("axios")

const GET_USERDATA = gql`
  query formPrecious {
    formPrecious {
      firstCheckTierDate
      fullName
      phoneNumber
      nik
      memberTier
      count
    }
  }
`

const UPDATE_COUNTER = gql`
  mutation updatePrecious(
    $firstCheckTierDate: String
    $fullName: String
    $phoneNumber: String 
    $nik: String
    $memberTier: String
    $count: String
    ) {
    updatePrecious(input: {
      clientMutationId: ""
      firstCheckTierDate: $firstCheckTierDate
      fullName: $fullName
      phoneNumber: $phoneNumber
      nik: $nik
      memberTier: $memberTier
      count: $count
    }) {
      firstCheckTierDate
      fullName
      phoneNumber
      nik
      memberTier
      count
      message
      status
    }
  }
`

const SUBMIT_COUNTER = gql`
  mutation submit2Precious(
    $firstCheckTierDate: String
    $fullName: String
    $phoneNumber: String
    $nik: String
    $memberTier: String
    $count: String
  ) {
    submit2Precious(
      input: {
        clientMutationId: ""
        firstCheckTierDate: $firstCheckTierDate
        fullName: $fullName
        phoneNumber: $phoneNumber
        nik: $nik
        memberTier: $memberTier
        count: $count
      }
    ) {
      status
      message
    }
  }
`

const urlProjectList = (caption) => {
  let urlProject
  switch(caption) {
    case "bsdcity-logo":
      urlProject = "https://bsdcity.com/?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "banjarwijaya-logo":
      urlProject = "https://www.sinarmasland.com/development/residential/banjar-wijaya?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "kotawisata-logo":
      urlProject = "https://www.kota-wisata.com/?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "kotawisatacibubur-logo":
      urlProject = "https://www.kota-wisata.com/?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "legendawisata-logo":
      urlProject = "https://www.sinarmasland.com/development/residential/legenda-wisata?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "wisatabukitmas-logo":
      urlProject = "https://www.sinarmasland.com/development/residential/wisata-bukit-mas?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "theelements-logo":
      urlProject = "https://theelements.co.id/en?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "southgate-logo":
      urlProject = "https://southgate.id/?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "aerium-logo":
      urlProject = "https://aerium-residences.com/?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "klaska-logo":
      urlProject = "https://klaskaresidence.com/klaska-frontend/?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "grandcity-logo":
      urlProject = "https://grandcitybalikpapan.com/en/?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
    case "nuvasabay-logo":
      urlProject = "https://www.nuvasabay.com/?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_Precious_2023&utm_content=Website_SML_Precious";
      break;
  }
  return urlProject
}

const Headings = ({ data }) => {
  return(
    <div className="container heading heading-basic mw-100 precious-header">
      {data?.attributes?.level === 1 && (<h1 className={data?.attributes?.className} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h1>)}
      {data?.attributes?.level === 2 && (<h2 className={data?.attributes?.className} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h2>)}
      {data?.attributes?.level === 3 && (<h3 className={data?.attributes?.className} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h3>)}
      {data?.attributes?.level === 4 && (<h4 className={data?.attributes?.className} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h4>)}
      {data?.attributes?.level === 5 && (<h5 className={data?.attributes?.className} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h5>)}
    </div>
  )
}

const CardDurationProgram = ({ data }) => {
  return(
    <Container className="row text-center m-0 mw-100">
      {data && data.acf && data.acf.list.map(item => (
        <div className="col-md-4 mb-3">
          <div className="card card-basic mb-3">
            <div className="row row-0">
              <div className="col-body col-12">
                <img width={"150"} src={item?.img?.sourceUrl} />
              </div>
              <div className="col-bg col-12">
                <p className="mon-text-style mt-3">{item?.title}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Container>
  )
}

const PreciousPage = ({ location }) => {

  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        page(id: "precious", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          preciousAdditionalBlock {
            benefitTable
            memberBenefit {
              ... on WordPress_Page_Preciousadditionalblock_MemberBenefit_AddMember {
                title
                tier
                image {
                  sourceUrl
                }
                assetDetail {
                  ... on WordPress_Page_Preciousadditionalblock_MemberBenefit_AddMember_AssetDetail_AddAssetDetail {
                    image {
                      sourceUrl
                    }
                    totalAsset
                  }
                }
                productDetail {
                  ... on WordPress_Page_Preciousadditionalblock_MemberBenefit_AddMember_ProductDetail_AddProductDetail {
                    image {
                      sourceUrl
                    }
                    productInStock
                    newProduct
                  }
                }
                incentiveDetail {
                  ... on WordPress_Page_Preciousadditionalblock_MemberBenefit_AddMember_IncentiveDetail_AddIncentiveDetail {
                    image {
                      sourceUrl
                    }
                    refferalIncentive
                  }
                }
              }
            }
            faq {
              ... on WordPress_Page_Preciousadditionalblock_Faq_AddFaq {
                question
                answer
              }
            }
            coverSlider {
              ... on WordPress_Page_Preciousadditionalblock_CoverSlider_AddContent {
                desktopImage{
                  sourceUrl
                }
                mobileImage{
                  sourceUrl
                }
              }
            }
          }
          blocks {
            name
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
            ... on WordPress_CoreHeadingBlock {
              name
              attributes {
                content
                level
                className
              }
            }
            ... on WordPress_CoreShortcodeBlock {
              name
              attributes {
                text
              }
            }
            ... on WordPress_CoreParagraphBlock {
              name
              attributes {
                ... on WordPress_CoreParagraphBlockAttributesV3 {
                  align
                  backgroundColor
                  content
                  className
                }
              }
            }
            ... on WordPress_AcfListImageBlock {
              name
              attributes {
                className
              }
              acf {
                title
                text
                list {
                  img {
                    sourceUrl
                  }
                  title
                  text
                }
              }
            }
            ... on WordPress_AcfIconlistBlock {
              parentId
              acf {
                list {
                  icon {
                    sourceUrl
                  }
                  title
                  description
                }
              }
            }
            ... on WordPress_AcfAcfgalleryBlock {
              name
              attributes {
                className
              }
              acf {
                carousel_type
                images {
                  title
                  slug
                  sourceUrl
                  caption
                  description
                }
              }
            }
            ... on WordPress_CoreButtonsBlock {
              name
              attributes {
                className
              }
              innerBlocks {
                name
                ... on WordPress_CoreButtonBlock {
                  parentId
                  name
                  attributes {
                    ... on WordPress_CoreButtonBlockAttributesV2 {
                      text
                      className
                    }
                  }
                }
              }
            }
            ... on WordPress_CoreGroupBlock {
              name
              attributes {
                className
              }
              innerBlocks {
                ... on WordPress_CoreHeadingBlock {
                  name
                  attributes {
                    content
                    level
                  }
                }
                ... on WordPress_CoreParagraphBlock {
                  name
                  attributes {
                    ... on WordPress_CoreParagraphBlockAttributesV3 {
                      align
                      backgroundColor
                      content
                      className
                    }
                  }
                  originalContent
                }
                ... on WordPress_CoreImageBlock {
                  name
                  attributes {
                    url
                    title
                    className
                    href
                  }
                }
                ... on WordPress_AcfAcfgalleryBlock {
                  name
                  acf {
                    carousel_type
                    images {
                      title
                      slug
                      sourceUrl
                      caption(format: RAW)
                      description
                    }
                  }
                }
                ... on WordPress_CoreListBlock {
                  name
                  attributes {
                    values
                  }
                }
                ... on WordPress_CoreShortcodeBlock {
                  name
                  attributes {
                    text
                  }
                }
                ... on WordPress_AcfListImageBlock {
                  name
                  attributes {
                    className
                  }
                  acf {
                    title
                    text
                    list {
                      img {
                        sourceUrl
                      }
                      title
                      text
                    }
                  }
                }
                ... on WordPress_CoreGroupBlock {
                  name
                  attributes {
                    className
                  }
                  innerBlocks {
                    ... on WordPress_CoreHeadingBlock {
                      name
                      attributes {
                        content
                        level
                      }
                    }
                    ... on WordPress_CoreParagraphBlock {
                      name
                      attributes {
                        ... on WordPress_CoreParagraphBlockAttributesV3 {
                          align
                          backgroundColor
                          content
                          className
                        }
                      }
                    }
                    ... on WordPress_CoreImageBlock {
                      name
                      attributes {
                        url
                        title
                        className
                      }
                    }
                    ... on WordPress_AcfAcfgalleryBlock {
                      name
                      acf {
                        carousel_type
                        images {
                          title
                          slug
                          sourceUrl
                          caption(format: RAW)
                          description(format: RAW)
                        }
                      }
                    }
                    ... on WordPress_CoreListBlock {
                      name
                      attributes {
                        values
                      }
                    }
                    ... on WordPress_CoreShortcodeBlock {
                      name
                      attributes {
                        text
                      }
                    }
                    ... on WordPress_AcfListImageBlock {
                      name
                      attributes {
                        className
                      }
                      acf {
                        title
                        text
                        list {
                          img {
                            sourceUrl
                          }
                          title
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
            focuskw
          }
        }
      }
    }
  `)

  const buttonScrollHandler = (target) => {
    var scrollContainer = target
    do { //find scroll container
      scrollContainer = scrollContainer.parentNode
      if (!scrollContainer) return
      scrollContainer.scrollTop += 1
    } while (scrollContainer.scrollTop == 0)
  
    var targetY = -65
    do { //find the top of target relatively to the container
      if (target == scrollContainer) break
      targetY += target.offsetTop
    } while (target = target.offsetParent)
  
    const scroll = function(c, a, b, i) {
      i++; if (i > 30) return
      c.scrollTop = a + (b - a) / 30 * i
      setTimeout(function(){ scroll(c, a, b, i); }, 20)
    }
    // start scrolling
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0)
  }

  const cookies = new Cookies()
  const [getUser,{ data:userDataLazy }] = useLazyQuery(GET_USERDATA)

  const [updateCounter, 
    { 
      loading: updateCounterLoading 
    }
  ] = useMutation(UPDATE_COUNTER)

  const [submitCounter, 
    { 
      loading: submitCounterLoading 
    }
  ] = useMutation(SUBMIT_COUNTER)

  let iconListItems = []
  let coverItems = []
  let nikList = []
  let coverImageDesktopList = []
  let coverImageMobileList = []
  const [btnSubmitLoading, setBtnSubmitLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [resultTierModal, setResultTierModal] = useState(false)
  const [tierBenefit, setTierBenefit] = useState(false)
  const [formValues, setFormValues] = useState({
    nik: "",
  })
  const [tierList, setTierList] = useState({})
  const [errMsg, setErrMsg] = useState("")
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const ogUrl = process.env.BASE_URL + location.pathname
  const wordPress = staticData?.wordPress?.page
  const seo = wordPress?.seo
  const featuredImage = wordPress?.featuredImage?.mediaItemUrl
  let dataListLazy = userDataLazy && userDataLazy.formPrecious
  let urlWithHash = location?.hash
  let triggerTnb = useRef("all")
  const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss')

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const getCookie = (cname) => {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    accessibility: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const coverSettings = {
    autoplay: true,
    autoplaySpeed: 4000,
    accessibility: true,
    dots: true,
    dotsClass: "slick-dots dots-precious-lg",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
  }

  // eslint-disable-next-line
  wordPress && wordPress?.preciousAdditionalBlock?.coverSlider?.map(item => {
    coverImageDesktopList.push(item?.desktopImage?.sourceUrl)
    coverImageMobileList.push(item?.mobileImage?.sourceUrl)
  })

  // eslint-disable-next-line
  wordPress && wordPress?.blocks?.map(item => {
    if(item.name === "core/cover"){
      coverItems.push({
        url: item?.attributes?.url, 
        className: item?.attributes?.className
      })
    }else if(item.name === "acf/iconlist"){
      item?.acf?.list.map(list => (
        iconListItems.push({
          name: list?.title,
          sourceUrl: list?.icon?.sourceUrl,
          target: "#"+list?.title
        })
      ))
    }
  })

  dataListLazy != undefined && dataListLazy.forEach(element => {
    nikList.push(element.nik)
  })

  const handleCloseSuccessModal = () => {{
    setSuccessModal(false)
    if (errMsg) {
      setErrMsg("")
    }
  }}

  const handleCloseResultTierModal = () => {{
    setResultTierModal(false)
  }}

  const handleLearnMore = () => {{
    buttonScrollHandler(document.getElementById("tnb-header"))
  }}

  const handleDiscountFamily = () => {{
    const accHeader5 = document.getElementById('accordion__heading-raa-5')
    const panelAcc5 = document.getElementById('accordion__panel-raa-5')
    buttonScrollHandler(document.getElementById("faq-precious-5"))
    if (accHeader5) {
      accHeader5.setAttribute('aria-expanded', 'true')
    }
    if (panelAcc5) {
      panelAcc5.removeAttribute('hidden')
    }

  }}

  const handleTierBenefit = (newTier) => {
    setTierBenefit(true)
    triggerTnb.current = newTier

    const bronzePoint = document.getElementById("bronzePoint");
    const silverPoint = document.getElementById("silverPoint");
    const goldPoint = document.getElementById("goldPoint");
    const platinumPoint = document.getElementById("platinumPoint");

    const detailBronze = document.getElementById("detail-Bronze")
    const detailSilver = document.getElementById("detail-Silver")
    const detailGold = document.getElementById("detail-Gold")
    const detailPlatinum = document.getElementById("detail-Platinum")
  
    switch (triggerTnb.current) {
      case "bronze":
        bronzePoint.classList.add("is-bronze")
        silverPoint.classList.remove("is-silver")
        goldPoint.classList.remove("is-gold")
        platinumPoint.classList.remove("is-platinum")

        detailBronze.classList.replace("d-none", "d-block")
        detailSilver.classList.replace("d-block", "d-none")
        detailGold.classList.replace("d-block", "d-none")
        detailPlatinum.classList.replace("d-block", "d-none")
        break;
      case "silver":
        bronzePoint.classList.add("is-bronze")
        silverPoint.classList.add("is-silver")
        goldPoint.classList.remove("is-gold")
        platinumPoint.classList.remove("is-platinum")

        detailBronze.classList.replace("d-block", "d-none")
        detailSilver.classList.replace("d-none", "d-block")
        detailGold.classList.replace("d-block", "d-none")
        detailPlatinum.classList.replace("d-block", "d-none")
        break;
      case "gold":
        bronzePoint.classList.add("is-bronze")
        silverPoint.classList.add("is-silver")
        goldPoint.classList.add("is-gold")
        platinumPoint.classList.remove("is-platinum")

        detailBronze.classList.replace("d-block", "d-none")
        detailSilver.classList.replace("d-block", "d-none")
        detailGold.classList.replace("d-none", "d-block")
        detailPlatinum.classList.replace("d-block", "d-none")
        break;
      case "platinum":
        bronzePoint.classList.add("is-bronze")
        silverPoint.classList.add("is-silver")
        goldPoint.classList.add("is-gold")
        platinumPoint.classList.add("is-platinum")

        detailBronze.classList.replace("d-block", "d-none")
        detailSilver.classList.replace("d-block", "d-none")
        detailGold.classList.replace("d-block", "d-none")
        detailPlatinum.classList.replace("d-none", "d-block")
        break;
      default:
        // Handle default case if needed
        break;
    }
  }

  const reqNik = async (param) => {
    const url = process.env.BASE_URL + "/api/request-nik"
    const timestamp = new Date().getTime()
    const expire = new Date(timestamp + 5 * 60 * 1000)
    let cnik = getCookie("nik")
    var data = JSON.stringify({
      "events": {
        "NoKTP": param
      }
    })
    const config = {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    }

    setBtnSubmitLoading(true)

    try {
      const response = await axios(config)
      console.log("response_req_nik =>", JSON.stringify(response.data))

      if (response?.data?.CustomerCollection) {
        setTierList(response?.data?.CustomerCollection?.Customer)

        if (nikList.length < 1) {
          if (cnik) {
            await updateCounter({
              variables: {
                firstCheckTierDate: currentDateTime,
                fullName: response?.data?.CustomerCollection?.Customer?.Name,
                phoneNumber: response?.data?.CustomerCollection?.Customer?.Mobile,
                nik: response?.data?.CustomerCollection?.Customer?.NoKTP,
                memberTier: response?.data?.CustomerCollection?.Customer?.TierList,
                count: 1
              },
            }).then(res => {
              if (res.data.updatePrecious.status === "success") {
                console.log("Data berhasil diubah")
              }else{
                console.log("Data gagal diubah")
              }
            })
          } else {
            await submitCounter({
              variables: {
                firstCheckTierDate: currentDateTime,
                fullName: response?.data?.CustomerCollection?.Customer?.Name,
                phoneNumber: response?.data?.CustomerCollection?.Customer?.Mobile,
                nik: response?.data?.CustomerCollection?.Customer?.NoKTP,
                memberTier: response?.data?.CustomerCollection?.Customer?.TierList,
                count: 1
              },
            }).then(res => {
              if (res.data.submit2Precious.status === "success") {
                cookies.set('nik', response?.data?.CustomerCollection?.Customer?.NoKTP, { path: '/', expires: new Date(expire) })
                console.log("Data pertama berhasil tersimpan")
              }else{
                console.log("Data pertama gagal tersimpan")
              }
            })
          }
        } else if (nikList && nikList.length > 0) {
          const found = nikList.some(item => item === response?.data?.CustomerCollection?.Customer?.NoKTP)
          const dateTimeFound = dataListLazy.find(item => item.nik === response?.data?.CustomerCollection?.Customer?.NoKTP)?.firstCheckTierDate
          if (found) {
            if (response?.data?.CustomerCollection?.Customer?.TierList !== "" && response?.data?.CustomerCollection?.Customer?.TierList !== null) {
              console.log("masuk-f1")
              await updateCounter({
                variables: {
                  firstCheckTierDate: dateTimeFound ? dateTimeFound : currentDateTime,
                  fullName: response?.data?.CustomerCollection?.Customer?.Name,
                  phoneNumber: response?.data?.CustomerCollection?.Customer?.Mobile,
                  nik: response?.data?.CustomerCollection?.Customer?.NoKTP,
                  memberTier: response?.data?.CustomerCollection?.Customer?.TierList,
                  count: 1
                },
              }).then(res => {
                if (res.data.updatePrecious.status === "success") {
                  console.log("Data berhasil diubah")
                }else{
                  console.log("Data gagal diubah")
                }
              })
            } else {
              console.log("masuk-f2")
              await updateCounter({
                variables: {
                  firstCheckTierDate: dateTimeFound ? dateTimeFound : currentDateTime,
                  fullName: response?.data?.CustomerCollection?.Customer?.Name,
                  phoneNumber: response?.data?.CustomerCollection?.Customer?.Mobile,
                  nik: response?.data?.CustomerCollection?.Customer?.NoKTP,
                  memberTier: response?.data?.CustomerCollection?.Customer?.TierList,
                  count: 1
                },
              }).then(res => {
                if (res.data.updatePrecious.status === "success") {
                  console.log("Data berhasil dihapus dari database")
                  setErrMsg("NIK/Paspor tidak terdaftar")
                }else{
                  console.log("Data gagal dihapus dari database")
                }
              })
            }
          } else {
            if (cnik) {
              await updateCounter({
                variables: {
                  firstCheckTierDate: dateTimeFound ? dateTimeFound : currentDateTime,
                  fullName: response?.data?.CustomerCollection?.Customer?.Name,
                  phoneNumber: response?.data?.CustomerCollection?.Customer?.Mobile,
                  nik: response?.data?.CustomerCollection?.Customer?.NoKTP,
                  memberTier: response?.data?.CustomerCollection?.Customer?.TierList,
                  count: 1
                },
              })
            } else {
              if (response?.data?.CustomerCollection?.Customer?.TierList !== "" && response?.data?.CustomerCollection?.Customer?.TierList !== null) {
                await submitCounter({
                  variables: {
                    firstCheckTierDate: currentDateTime,
                    fullName: response?.data?.CustomerCollection?.Customer?.Name,
                    phoneNumber: response?.data?.CustomerCollection?.Customer?.Mobile,
                    nik: response?.data?.CustomerCollection?.Customer?.NoKTP,
                    memberTier: response?.data?.CustomerCollection?.Customer?.TierList,
                    count: 1
                  },
                }).then(res => {
                  if (res.data.submit2Precious.status === "success") {
                    cookies.set('nik', response?.data?.CustomerCollection?.Customer?.NoKTP, { path: '/', expires: new Date(expire) })
                    console.log("Data berhasil tersimpan")
                  }else{
                    console.log("Data gagal tersimpan")
                  }
                })
              } else {
                setErrMsg("NIK/Paspor tidak terdaftar")
              }
            }
          }
        }
        if (response?.data?.CustomerCollection?.Customer?.TierList !== "" && response?.data?.CustomerCollection?.Customer?.TierList !== null) {
          setSuccessModal(false)
          setResultTierModal(true)
          setErrMsg("")
        }
        // setSuccessModal(false)
        // setResultTierModal(true)
        // setErrMsg("")
      } else {
        setErrMsg("NIK/Paspor tidak ditemukan")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setBtnSubmitLoading(false)
    }
  }

  const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
    const nikHandler = (val, setFieldValue) => {
      setFieldValue("nik", val)
      if (errMsg) {
        setErrMsg("")
      }
    }
  
    return (
      <Form className="row pt-4" id="submissionModalForm">
        <div className="col-12 col-md-12 pb-3">
          {/* <NumberFormat
            name="nik"
            className="form-control px-0"
            placeholder="Masukkan Nomor KTP Anda"
            allowEmptyFormatting
            value={values.nik}
            onValueChange={(val) =>
              nikHandler(val.formattedValue, setFieldValue)
            }
            id="customerNik"
          /> */}
          <input
            type="text"
            name="nik"
            className="form-control px-0"
            placeholder="Masukkan Nomor KTP/Paspor Anda"
            value={values.nik}
            onChange={(e) =>
              nikHandler(e.target.value, setFieldValue)
            }
            id="customerNik"
          />
          {touched.nik && errors.nik && (
            <div className="invalid-value d-block">{errors.nik}</div>
          )}
          {errMsg != "" && (
            <div className="invalid-value d-block">{errMsg}</div>
          )}
        </div>
        
        <div className="col-12 pt-5">
          <button className="btn btn-popup-precious w-100 w-md-25" disabled={btnSubmitLoading}>
            {btnSubmitLoading ? (<span>Please Wait <i className="fa fa-spinner fa-spin"></i></span>) : ("Submit")}
          </button>
        </div>
      </Form>
    )
  }

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ nik, }) {
      return {
        nik: formValues.nik || nik,
      }
    },
    validationSchema: Yup.object().shape({
      nik: Yup.string()
        .matches(/^[a-zA-Z0-9]{7,16}$/, 'NIK/Paspor harus terdiri dari huruf atau angka, minimal 7 sampai 16 karakter')
        .required('NIK/Paspor wajib diisi'),
    }),
    handleSubmit(values) {
      setFormValues(values)
      console.log(values)

      reqNik(values?.nik)
    },
  })(LeadForm)

  useEffect(() => {
    // eslint-disable-next-line
    wordPress && wordPress?.blocks?.map(item => {
      if(item.name === "core/cover"){
        coverItems.push({
          url: item?.attributes?.url, 
          className: item?.attributes?.className
        })
      }
    })

    // eslint-disable-next-line
    wordPress && wordPress?.preciousAdditionalBlock?.coverSlider?.map(item => {
      coverImageDesktopList.push(item?.desktopImage?.sourceUrl)
      coverImageMobileList.push(item?.mobileImage?.sourceUrl)
    })
  }, [])

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    const bronzePoint = document.getElementById("bronzePoint")
    const silverPoint = document.getElementById("silverPoint")
    const goldPoint = document.getElementById("goldPoint")
    const platinumPoint = document.getElementById("platinumPoint")
    
    const navTierBronze = document.getElementById("nav-tier-bronze")
    const navTierSilver = document.getElementById("nav-tier-silver")
    const navTierGold = document.getElementById("nav-tier-gold")
    const navTierPlatinum = document.getElementById("nav-tier-platinum")

    const detailBronze = document.getElementById("detail-Bronze")
    const detailSilver = document.getElementById("detail-Silver")
    const detailGold = document.getElementById("detail-Gold")
    const detailPlatinum = document.getElementById("detail-Platinum")

    const medalOrder = ['bronze', 'silver', 'gold', 'platinum']

    function updateMedal() {
      if (triggerTnb.current === "all") {
        setTimeout(() => {
          bronzePoint.classList.remove("is-bronze")
          silverPoint.classList.remove("is-silver")
          goldPoint.classList.remove("is-gold")
          platinumPoint.classList.remove("is-platinum")

          detailBronze.classList.replace("d-block", "d-none")
          detailSilver.classList.replace("d-block", "d-none")
          detailGold.classList.replace("d-block", "d-none")
          detailPlatinum.classList.replace("d-block", "d-none")

          if (resizeScreen) {
            navTierBronze.classList.remove("slide-left-tier")
            navTierSilver.classList.remove("slide-left-tier")
            navTierGold.classList.remove("slide-left-tier")
            navTierPlatinum.classList.remove("slide-left-tier")

            navTierBronze.classList.add("slide-all-tier")
            navTierSilver.classList.add("slide-all-tier")
            navTierGold.classList.add("slide-all-tier")
            navTierPlatinum.classList.add("slide-all-tier")
          }else{
            navTierBronze.classList.remove("slide-left-tier")
            navTierSilver.classList.remove("slide-left-tier")
            navTierGold.classList.remove("slide-left-tier")
            navTierPlatinum.classList.remove("slide-left-tier")

            navTierBronze.classList.add("slide-right-tier")
            navTierSilver.classList.add("slide-right-tier")
            navTierGold.classList.add("slide-right-tier")
            navTierPlatinum.classList.add("slide-right-tier")
          }
          triggerTnb.current = "bronze"
        }, 3000)
      }
      const currentIndex = medalOrder.indexOf(triggerTnb.current);
      const nextIndex = (currentIndex + 1) % medalOrder.length;
    
      triggerTnb.current = medalOrder[nextIndex];

      switch (triggerTnb.current) {
        case "bronze":
          bronzePoint.classList.add("is-bronze")
          silverPoint.classList.remove("is-silver")
          goldPoint.classList.remove("is-gold")
          platinumPoint.classList.remove("is-platinum")

          detailBronze.classList.replace("d-none", "d-block")
          detailSilver.classList.replace("d-block", "d-none")
          detailGold.classList.replace("d-block", "d-none")
          detailPlatinum.classList.replace("d-block", "d-none")

          if (resizeScreen) {
            navTierBronze.classList.remove("slide-left-tier")
            navTierSilver.classList.remove("slide-left-tier")
            navTierGold.classList.remove("slide-left-tier")
            navTierPlatinum.classList.remove("slide-left-tier")

            navTierBronze.classList.add("slide-right-tier")
            navTierSilver.classList.add("slide-right-tier")
            navTierGold.classList.add("slide-right-tier")
            navTierPlatinum.classList.add("slide-right-tier")

            navTierBronze.classList.remove("slide-all-tier")
            navTierSilver.classList.remove("slide-all-tier")
            navTierGold.classList.remove("slide-all-tier")
            navTierPlatinum.classList.remove("slide-all-tier")
          }else{
            navTierBronze.classList.remove("slide-left-tier")
            navTierSilver.classList.remove("slide-left-tier")
            navTierGold.classList.remove("slide-left-tier")
            navTierPlatinum.classList.remove("slide-left-tier")

            navTierBronze.classList.add("slide-right-tier")
            navTierSilver.classList.add("slide-right-tier")
            navTierGold.classList.add("slide-right-tier")
            navTierPlatinum.classList.add("slide-right-tier")

            navTierBronze.classList.remove("slide-all-tier")
            navTierSilver.classList.remove("slide-all-tier")
            navTierGold.classList.remove("slide-all-tier")
            navTierPlatinum.classList.remove("slide-all-tier")
          }
          break;
        case "silver":
          bronzePoint.classList.add("is-bronze")
          silverPoint.classList.add("is-silver")
          goldPoint.classList.remove("is-gold")
          platinumPoint.classList.remove("is-platinum")

          detailBronze.classList.replace("d-block", "d-none")
          detailSilver.classList.replace("d-none", "d-block")
          detailGold.classList.replace("d-block", "d-none")
          detailPlatinum.classList.replace("d-block", "d-none")

          if (resizeScreen) {
            navTierBronze.classList.remove("slide-left-tier")
            navTierSilver.classList.remove("slide-left-tier")
            navTierGold.classList.remove("slide-left-tier")
            navTierPlatinum.classList.remove("slide-left-tier")

            navTierBronze.classList.add("slide-right-tier")
            navTierSilver.classList.add("slide-right-tier")
            navTierGold.classList.add("slide-right-tier")
            navTierPlatinum.classList.add("slide-right-tier")

            navTierBronze.classList.remove("slide-all-tier")
            navTierSilver.classList.remove("slide-all-tier")
            navTierGold.classList.remove("slide-all-tier")
            navTierPlatinum.classList.remove("slide-all-tier")
          }else{
            navTierBronze.classList.remove("slide-left-tier")
            navTierSilver.classList.remove("slide-left-tier")
            navTierGold.classList.remove("slide-left-tier")
            navTierPlatinum.classList.remove("slide-left-tier")

            navTierBronze.classList.add("slide-right-tier")
            navTierSilver.classList.add("slide-right-tier")
            navTierGold.classList.add("slide-right-tier")
            navTierPlatinum.classList.add("slide-right-tier")

            navTierBronze.classList.remove("slide-all-tier")
            navTierSilver.classList.remove("slide-all-tier")
            navTierGold.classList.remove("slide-all-tier")
            navTierPlatinum.classList.remove("slide-all-tier")
          }
          break;
        case "gold":
          bronzePoint.classList.add("is-bronze")
          silverPoint.classList.add("is-silver")
          goldPoint.classList.add("is-gold")
          platinumPoint.classList.remove("is-platinum")

          detailBronze.classList.replace("d-block", "d-none")
          detailSilver.classList.replace("d-block", "d-none")
          detailGold.classList.replace("d-none", "d-block")
          detailPlatinum.classList.replace("d-block", "d-none")

          if (resizeScreen) {
            navTierBronze.classList.remove("slide-left-tier")
            navTierSilver.classList.remove("slide-left-tier")
            navTierGold.classList.remove("slide-left-tier")
            navTierPlatinum.classList.remove("slide-left-tier")

            navTierBronze.classList.add("slide-right-tier")
            navTierSilver.classList.add("slide-right-tier")
            navTierGold.classList.add("slide-right-tier")
            navTierPlatinum.classList.add("slide-right-tier")

            navTierBronze.classList.remove("slide-all-tier")
            navTierSilver.classList.remove("slide-all-tier")
            navTierGold.classList.remove("slide-all-tier")
            navTierPlatinum.classList.remove("slide-all-tier")
          }else{
            navTierBronze.classList.remove("slide-left-tier")
            navTierSilver.classList.remove("slide-left-tier")
            navTierGold.classList.remove("slide-left-tier")
            navTierPlatinum.classList.remove("slide-left-tier")

            navTierBronze.classList.add("slide-right-tier")
            navTierSilver.classList.add("slide-right-tier")
            navTierGold.classList.add("slide-right-tier")
            navTierPlatinum.classList.add("slide-right-tier")

            navTierBronze.classList.remove("slide-all-tier")
            navTierSilver.classList.remove("slide-all-tier")
            navTierGold.classList.remove("slide-all-tier")
            navTierPlatinum.classList.remove("slide-all-tier")
          }
          break;
        case "platinum":
          bronzePoint.classList.add("is-bronze")
          silverPoint.classList.add("is-silver")
          goldPoint.classList.add("is-gold")
          platinumPoint.classList.add("is-platinum")

          detailBronze.classList.replace("d-block", "d-none")
          detailSilver.classList.replace("d-block", "d-none")
          detailGold.classList.replace("d-block", "d-none")
          detailPlatinum.classList.replace("d-none", "d-block")

          if (resizeScreen) {
            navTierBronze.classList.remove("slide-right-tier")
            navTierSilver.classList.remove("slide-right-tier")
            navTierGold.classList.remove("slide-right-tier")
            navTierPlatinum.classList.remove("slide-right-tier")

            navTierBronze.classList.add("slide-left-tier")
            navTierSilver.classList.add("slide-left-tier")
            navTierGold.classList.add("slide-left-tier")
            navTierPlatinum.classList.add("slide-left-tier")

            navTierBronze.classList.remove("slide-all-tier")
            navTierSilver.classList.remove("slide-all-tier")
            navTierGold.classList.remove("slide-all-tier")
            navTierPlatinum.classList.remove("slide-all-tier")
          }else{
            navTierBronze.classList.remove("slide-left-tier")
            navTierSilver.classList.remove("slide-left-tier")
            navTierGold.classList.remove("slide-left-tier")
            navTierPlatinum.classList.remove("slide-left-tier")

            navTierBronze.classList.add("slide-right-tier")
            navTierSilver.classList.add("slide-right-tier")
            navTierGold.classList.add("slide-right-tier")
            navTierPlatinum.classList.add("slide-right-tier")

            navTierBronze.classList.remove("slide-all-tier")
            navTierSilver.classList.remove("slide-all-tier")
            navTierGold.classList.remove("slide-all-tier")
            navTierPlatinum.classList.remove("slide-all-tier")
          }

          break;
        default:
          break;
      }
    }
    
    // setInterval(updateMedal, 4000)
    const intervalId = setInterval(updateMedal, 4000)

    // Cleanup the interval when the component unmounts or when triggerTnb.current changes.
    return () => clearInterval(intervalId)
  }, [triggerTnb.current])

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    let vLine = document.querySelector(".v-line")
    let langItem = document.querySelectorAll(".lang-item")
    let langItemActive = document.querySelector(".lang-item.active")
    // let coverFullTop = document.querySelector(".cover-full-top")
    let tnbHeader = document.querySelector(".tnb-header")

    if(resizeScreen){
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }

    for (let i = 0; i < langItem.length; i++) {
      langItem[i].style.color = "#000"
    }

    if (tnbHeader) {
      tnbHeader.id = "tnb-header"
    }

    vLine.style.backgroundColor = "#000"
    langItemActive.style.color = "#AB0204"
    // coverFullTop.style.marginTop = "60px"
  }, [resizeScreen])
  
  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        url={ogUrl}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        img={featuredImage}
        fbImg={featuredImage}
      />
      <Helmet>
        <body className="bd-page" />
        <meta 
          name="keywords" 
          content={seo?.focuskw ? seo?.focuskw : ""}
        ></meta>
      </Helmet>
      {coverImageDesktopList && coverImageMobileList ? (
        <Section className="header-precious-banner p-0" style={{padding:"0px"}}>
          <Slider {...coverSettings}>
            {!resizeScreen ? (
              coverImageDesktopList.map((item, i) => {
                return(
                  <div key={i}>
                    <img className="slider-img-precious desktop-image" src={item} />
                  </div>
                )
              })
            ):(
              coverImageMobileList.map((item, i) => {
                return(
                  <div key={i}>
                    <img className="slider-img-precious mobile-image" src={item} />
                  </div>
                )
              })
            )}
          </Slider>
        </Section>
      ):(
        <CoverMain
          h1={""}
          img={resizeScreen ? coverItems[1].url : coverItems[0]?.url}
          alt={wordPress?.title}
          className={`cover-precious ${resizeScreen ? coverItems[1]?.className : coverItems[0]?.className}`}
        /> 
      )}
      <Section>
        {/* <Container>
          <div className="row">
            <div className="col-md-12 pb-3">
              <button
                className="btn btn-primary btn-block" 
                onClick={()=>setSuccessModal(true)}>
                  Check Member Tier
              </button>
            </div>
          </div>
        </Container> */}
        <Container className={`precious-wrapper m-0 p-0 mw-100`}>
          <div className="row m-0">
            {/* {wordPress?.preciousAdditionalBlock?.memberBenefit.map(element => {
              if (tierList?.TierList == element?.tier) {
                console.log(formValues)
                return(
                  <div className="row col-md-12 member-benefit">
                    <div className="row col-md-12 image-place justify-content-md-center">
                      <div className="col-md-4 mb-3">
                        <CardPrecious
                          forwardKey={`post-${element?.tier}`}
                          title={element?.title}
                          img={element?.image?.sourceUrl}
                          titleClassName="card-member-title"
                          tierClassName="row justify-content-md-center"
                          className="mb-3"
                          nik={tierList?.NoKTP}
                          mobile={tierList?.Mobile}
                          name={tierList?.Name}
                        ></CardPrecious>
                      </div>
                    </div>
                  </div>
                )
              }
            })} */}

            {wordPress?.blocks?.map((item, i) => {
              switch (item?.name) {
                case "core/heading":
                  return(
                    <Headings key={`heading-${i}`} data={item} />
                  )
                case "core/paragraph":
                  return(
                    <p 
                      className={item?.attributes?.className 
                        ? item?.attributes?.className 
                        : ""
                      }
                      dangerouslySetInnerHTML={{
                        __html: item?.attributes?.content,
                      }}></p>
                  )
                case "acf/list-image":
                  if (item.attributes.className === "durasi-program") {
                    if (resizeScreen) {
                      return(<></>)
                    } else {
                      return(
                        <CardDurationProgram data={item} />
                      )
                    }
                  } else if (item.attributes.className === "kategori-produk") {
                    if (resizeScreen) {
                      return(
                        <></>
                      )
                    } else {
                      return(
                        <Container className="row text-center m-0 mw-100 product-category">
                          {item && item.acf && item.acf.list.map(listItem => (
                            <div className="col-md-3 mb-3">
                              <CardPrecious
                                forwardKey={`post-${listItem?.title}`}
                                title={listItem?.title}
                                img={listItem?.img?.sourceUrl}
                                titleClassName="mon-text-style card-member-title"
                                className="mb-3 pb-4"
                              ></CardPrecious>
                            </div>
                            )
                          )}
                        </Container>
                      )
                    }
                  }
                case "acf/iconlist":
                  return(
                    <TabLinkPrecious 
                      itemCenter
                      mobileCol={4}
                      textCenter
                      path="/precious"
                      data={iconListItems}
                      onTierChange={handleTierBenefit}
                    />
                  )
                case "core/shortcode":
                  if (item.attributes.text === "tier-checker"){
                    return(
                      <Container>
                        <div className="row">
                          <div className="col-md-12 pb-3 text-center">
                            <button
                              className="btn btn-tier-checker" 
                              onClick={()=>setSuccessModal(true)}>
                                KLIK DISINI
                            </button>
                          </div>
                        </div>
                      </Container>
                    )
                  } else if (item.attributes.text === "tier-n-benefit-detail"){
                    return(
                      <Container className="row text-center pb-3 pl-0 pr-0 mw-100 progress-wrapper m-0">
                        <div className="col-md-12">
                          <div className="progress-tier">
                            <div className="progress-tier-step is-bronze" id="bronzePoint">
                              <span className="progress-tier-icon"><i className="fa fa-star"></i></span>
                            </div>
                            <div className="progress-tier-step is-silver" id="silverPoint">
                              <span className="progress-tier-icon"><i className="fa fa-star"></i></span>
                            </div>
                            <div className="progress-tier-step is-gold" id="goldPoint">
                              <span className="progress-tier-icon"><i className="fa fa-star"></i></span>
                            </div>
                            <div className="progress-tier-step is-platinum" id="platinumPoint">
                              <span className="progress-tier-icon"><i className="fa fa-star"></i></span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          {wordPress?.preciousAdditionalBlock?.memberBenefit.map(element => {
                            return(
                              <CardTnB
                                id={`detail-${element?.title}`}
                                assetDetail={element?.assetDetail}
                                productDetail={element?.productDetail}
                                incentiveDetail={element?.incentiveDetail}
                                className={element?.title.toLowerCase() === triggerTnb.current ? "d-block" : "d-none"}
                              />                             
                            )
                          })}
                        </div>
                      </Container>
                    )
                  } else if (item.attributes.text === "faq-precious"){
                    return(
                      <Container className="pb-4">
                        {wordPress?.preciousAdditionalBlock?.faq.map((element, i) => {
                          return(
                            <Accordion allowZeroExpanded key={i}>
                              <AccordionItem id={`faq-precious-${i}`}>
                                <AccordionItemHeading id={`faq-heading-${i}`}>
                                  <AccordionItemButton>
                                    {element?.question}
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ReactMarkdown
                                    source={element?.answer}
                                    escapeHtml={false}
                                  />
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          )
                        })}
                      </Container>
                    )
                  }
                case "acf/acfgallery":
                  if (item.attributes.className === "galeri-durasi-program") {
                    if (resizeScreen) {
                      return(
                        <Container
                          className={`galeri-durasi-program mb-5 p-0 overflow-x-hidden`}
                          key={`gallery-${i}`}
                        >
                          {/* <Carousel data={item} /> */}
                          <CardSlider 
                            visibleInitial={false} 
                            noGutter={false} 
                            showInitial={`3`} 
                            arrowsSmDown={true} 
                            arrowsMdDown={true}
                          >
                            {item && item.acf && item.acf.images.map(listItem => {
                              return(
                                <Card
                                  key={listItem.title}
                                  variant="boxless"
                                  img={listItem.sourceUrl}
                                  imgHeight="h-ratio-3-2"
                                  className="card-nav"
                                  cardBgClassName="pb-5"
                                  caption={listItem?.caption ? listItem?.caption : ""}
                                  captionClassName="mon-text-style"
                                />
                              )
                            })}
                          </CardSlider>
                        </Container>
                      )
                    } else {
                      return(
                       <></> 
                      )
                    }
                } else if (item.attributes.className === "galeri-kategori-produk") {
                  if (resizeScreen) {
                    return(
                      <Container
                        className={`galeri-kategori-produk mb-5 p-0 overflow-x-hidden`}
                        key={`gallery-${i}`}
                      >
                        <CardSlider 
                          visibleInitial={false} 
                          noGutter={false} 
                          showInitial={`3`} 
                          arrowsSmDown={true} 
                          arrowsMdDown={true}
                        >
                          {item && item.acf && item.acf.images.map(listItem => {
                            return(
                              <Card
                                key={listItem.title}
                                variant="boxless"
                                img={listItem.sourceUrl}
                                imgHeight="h-ratio-3-2"
                                className="card-nav text-center"
                                title={listItem?.title ? listItem?.title : ""}
                                titleClassName="mon-text-style"
                              />
                            )
                          })}
                        </CardSlider>
                      </Container>
                    )
                  } else {
                    return(
                      <></>
                    )
                  }
                } else {
                    return(
                      <Container className="gallery-participating-project overflow-x-hidden">
                        <CardSlider 
                          visibleInitial={false} 
                          noGutter={false} 
                          showInitial={`3`} 
                          arrowsSmDown={true} 
                          arrowsMdDown={true}
                        >
                          {item && item.acf && item.acf.images.map(listItem => {
                            return(
                              <Card
                                key={listItem.title}
                                variant="overlay"
                                img={listItem.sourceUrl}
                                link={urlProjectList(listItem.title)}
                                imgHeight="h-ratio-3-2"
                                imgOverlay="0"
                                className="card-nav"
                              />
                            )
                          })}
                        </CardSlider>
                      </Container>
                    )
                  }
                case "core/buttons":
                  if (item?.innerBlocks[0]?.attributes?.className === "learn-more-button") {
                    return (
                      <div key={`button-${i}`} className="container text-center pt-0 pb-5">
                        <button
                          key={`${item?.innerBlocks[0]?.attributes?.text}-${i}`}
                          id={item?.innerBlocks[0]?.attributes?.className}
                          className={`btn ${item?.innerBlocks[0]?.attributes?.className}`}
                          onClick={handleLearnMore}
                          dangerouslySetInnerHTML={{
                            __html: item?.innerBlocks[0]?.attributes?.text,
                          }}
                        >
                        </button>
                      </div>
                    )
                  } else if (item?.innerBlocks[0]?.attributes?.className === "discount-f-family") {
                    return (
                      <div key={`button-${i}`} className="container text-center pt-0 pb-5">
                        <button
                          key={`${item?.innerBlocks[0]?.attributes?.text}-${i}`}
                          id={item?.innerBlocks[0]?.attributes?.className}
                          className={`btn ${item?.innerBlocks[0]?.attributes?.className}`}
                          onClick={handleDiscountFamily}
                          dangerouslySetInnerHTML={{
                            __html: item?.innerBlocks[0]?.attributes?.text,
                          }}
                        >
                        </button>
                      </div>
                    )
                  }
                default:
                  return <></>
              }
            })}
          </div>
        </Container>
      </Section>
      <Modal
        isOpen={successModal}
        onRequestClose={handleCloseSuccessModal}
        style={customStyles}
        contentLabel="campaign modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-popup-otp modal-body">
          <i className="fal fa-times float-right pr-3 pt-3" onClick={handleCloseSuccessModal}></i>
          <div className="container py-5 mw-100 mw-md-75 text-center">
            <p className="m-0">
              NIK/Paspor
            </p>
            <EnhancedLeadForm />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={resultTierModal}
        onRequestClose={handleCloseResultTierModal}
        style={customStyles}
        contentLabel="campaign modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-popup-otp modal-body">
          <i className="fal fa-times float-right pr-3 pt-3" onClick={handleCloseResultTierModal}></i>
          <div className="container py-5 mw-100 mw-md-75 text-center">
            {wordPress?.preciousAdditionalBlock?.memberBenefit.map(element => {
              if (tierList?.TierList == element?.tier) {
                console.log(formValues)
                return(
                  <div className="row">
                    <div className="col-md-12 pb-3">
                      <img src={element?.image?.sourceUrl} width="200"/>
                    </div>
                    <div className="col-md-12 text-center">
                      <span className="font-primary-precious">Name</span><br/>
                      <span>{tierList?.Name}</span><br/>
                      <span className="font-primary-precious">Phone Number</span><br/>
                      <span>{tierList?.Mobile}</span><br/>
                    </div>
                    <div className="row col-md-12 detail-tier-popup">
                      <div className="col-md-6" style={{display: "grid", placeItems: "center",}}>
                        <span className="font-primary-precious">Tier: {element?.tier}</span>
                      </div>
                      <div className="col-md-6 additional-discount">
                        <span><span className="font-primary-precious">Additional Discount:</span> </span><br/>
                        <span><span className="font-primary-precious">New Product:</span> {element?.productDetail[0]?.newProduct}</span><br/>
                        <span><span className="font-primary-precious">Product in Stock:</span> {element?.productDetail[0]?.productInStock}</span><br/>
                      </div>
                    </div>
                    <div className="col-md-12 disclaimer-tier-result">
                      <span>*Jika Anda ingin melakukan perubahan data, mohon hubungi Customer Service Sinar Mas Land :</span><br />
                      <span className="font-weight-bold">021 5038 0000</span>
                    </div>
                  </div>
                )
              }
            })}
            {/* {tierList?.TierList === "" && (
              <div className="row">
                <div className="col-md-12 pt-3 pb-3">
                  <span className="text-center">NIK/Paspor Tidak Terdaftar</span>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default PreciousPage
